import { __decorate, __metadata } from "tslib";
import { Component, Getter, namespace, Prop, Vue } from 'nuxt-property-decorator';
import AdminHeaderBell from "@/components/AdminHeaderBell.vue";
const ProfileModule = namespace('adminProfile');
const CompanyModule = namespace('company');
const NewsModule = namespace('news');
const AdminHeaderModule = namespace('AdminHeader');
let AdminHeader = class AdminHeader extends Vue {
    created() {
        this.$root.$setHeaderLoading = this.setHeaderLoading;
        this.getNewReleases();
        if (this.name && this.name.length)
            return;
    }
    async logOut() {
        await this.logOutUser();
        localStorage.removeItem('access_token');
        this.setPropertyCompany({ name: 'firstLink', value: null });
        await this.$router.push('/login');
    }
    get metaCss() {
        const { headerCssClass } = this.$route.meta;
        return headerCssClass || '';
    }
    setHeaderLoading(value) {
        this.setLoading(value);
    }
};
__decorate([
    ProfileModule.Action('getNewReleases'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "getNewReleases", void 0);
__decorate([
    ProfileModule.Action('logOutUser'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "logOutUser", void 0);
__decorate([
    ProfileModule.State('name'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "name", void 0);
__decorate([
    ProfileModule.State('surname'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "surname", void 0);
__decorate([
    ProfileModule.State('photo'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "photo", void 0);
__decorate([
    ProfileModule.State('email'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "email", void 0);
__decorate([
    ProfileModule.Getter('initials'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "initials", void 0);
__decorate([
    CompanyModule.Mutation('setProperty'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "setPropertyCompany", void 0);
__decorate([
    NewsModule.State('newsCounter'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "newsCounter", void 0);
__decorate([
    NewsModule.Action('getNewsCount'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "getNewsCount", void 0);
__decorate([
    AdminHeaderModule.State('dataLoading'),
    __metadata("design:type", Boolean)
], AdminHeader.prototype, "dataLoading", void 0);
__decorate([
    AdminHeaderModule.Mutation('setLoading'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "setLoading", void 0);
__decorate([
    Getter('COMPANY_ID'),
    __metadata("design:type", Object)
], AdminHeader.prototype, "COMPANY_ID", void 0);
__decorate([
    Prop({
        type: String,
        default: () => '',
        required: false
    }),
    __metadata("design:type", Object)
], AdminHeader.prototype, "cssClass", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false,
        required: false
    }),
    __metadata("design:type", Object)
], AdminHeader.prototype, "loading", void 0);
AdminHeader = __decorate([
    Component({
        components: { AdminHeaderBell }
    })
], AdminHeader);
export default AdminHeader;
