import LessonVideoTypes from './LessonVideoTypes';
import LessonAssessment from './LessonAssessment';
import LessonTypes from '@/helpers/enteties/LessonTypes';
import { testActions, testMove } from './LessonTestActions';
const statuses = [
    // {
    //   text: 'Бесплатный',
    //   icon: 'remove_red_eye',
    //   value: 'free',
    //   action_text: 'Сделать бесплатным'
    // },
    {
        text: 'Открытый',
        icon: 'remove_red_eye',
        value: 'visible',
        action_text: 'Открыть урок'
    },
    {
        text: 'Закрытый',
        icon: 'lock',
        value: 'close',
        action_text: 'Закрыть'
    },
    {
        text: 'Черновик',
        icon: 'edit',
        value: 'draft',
        action_text: 'Черновик'
    },
    {
        text: 'Удаленный',
        icon: 'delete',
        value: 'deleted',
        action_text: 'Удалить'
    }
];
const state = {
    id: 0,
    video_items: LessonVideoTypes,
    assessment: LessonAssessment,
    types: LessonTypes,
    test_actions: testActions,
    move_to: testMove,
    statuses,
    course_id: 0,
    name: '',
    description: '',
    status: '',
    currentStatus: {
        text: '',
        icon: '',
        value: '',
        action_text: ''
    },
    type: '',
    courseUrl: '',
    video: [
        {
            video_url: null,
            video_type: 'links'
        }
    ],
    webinar_video_recording: [
        {
            video_url: null,
            video_type: 'links'
        }
    ],
    material_files: [],
    homework_files: [],
    tabs: [],
    currentTab: {
        text: 'Содержимое урока',
        value: 'settings'
    },
    lessonUrl: '',
    testUrl: '',
    homework_max_points: 0,
    homework_text: '',
    use_comments: false,
    use_likes: false,
    show_likes: false,
    like_type: 'like_dislike',
    likes_count: 0,
    dislikes_count: 0,
    pleased_reactions_count: 0,
    displeased_reactions_count: 0,
    indifferent_reactions_count: 0,
    required_like_comment: false,
    homework_min_points: 1,
    course_use_comments: 'free',
    course_use_likes: 'free',
    test: null,
    test_questions: [],
    courses_move: [],
    lesson_move: [],
    lesson_open_by: null,
    lessons_open_id: [],
    check_criterion: null,
    open_type: '',
    open_days_count: null,
    open_final_date: null,
    close_date: null,
    journal_day_check: null,
    journal_type_check: null,
    lesson_date: null,
    time_duration: null,
    admin_access: [],
    webinar_mode: null,
    broadcast: null,
    template: null,
    master_id: null,
    master: null,
    webinar_status: null,
    webinar_settings_conference_layout_type: 'tile',
    webinar_settings_moderation_mode: 'pre_moderation',
    webinar_settings_hand_up: false,
    webinar_settings_presentation: false,
    webinar_settings_screencast: false,
    webinar_settings_video: false,
    webinar_settings_whiteboard: false,
    webinar_settings_chat: false,
    webinar_direct_link: '',
    my_webinar_link: '',
    webinar_data_exists: false,
    test_id: null,
    admin_access_users: [],
    webinar_file: [],
    course: null,
    admins_and_teachers: [],
    video_start_loading: null,
    isVideoLoading: false,
    cancelVideoLoading: null,
    blocks: [],
    template_id: null,
    journal_visit_enabled: false,
    video_lesson: [],
    other_media: [],
    showSuccess: false,
    errors: '',
    constructor_close_condition: {},
    forbidden_to_pass: false,
    forbid_datetime: '',
    lessons_to_open: []
};
export default state;
