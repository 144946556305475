import { __decorate, __metadata } from "tslib";
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
let CustomDatepicker = class CustomDatepicker extends Vue {
    constructor() {
        super(...arguments);
        this.date = '';
        this.menu = false;
    }
    get inputProps() {
        const defaultProps = {
            filled: true,
            outlined: true,
            hideDetails: 'auto',
            height: 40,
            clearable: true,
            readonly: true,
            'prepend-inner-icon': 'event'
        };
        return { ...defaultProps, ...this.textFieldProps };
    }
    inputChange() {
        this.$nextTick(() => {
            this.$emit('changeDate', { value: this.date, name: this.name });
        });
    }
    clearDate() {
        this.$set(this, 'date', '');
        this.$emit('changeDate', { value: this.date, name: this.name });
        this.$emit('input', this.date);
    }
    changeDate() {
        this.menu = false;
        this.$emit('changeDate', { value: this.date, name: this.name });
        this.$emit('input', this.date);
    }
    parseDate(date) {
        if (!date)
            return '';
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${this.isShortYear ? year.slice(2) : year}`;
    }
    formatDate(value = this.value) {
        if (!value)
            return '';
        if (!value.includes('T'))
            return this.value;
        const dateArr = value.split('T');
        return dateArr[0];
    }
    /**
     * метод для обновления/выставления даты из родительских компонентов
     * @public
     */
    setDate(value = '') {
        this.$set(this, 'date', this.formatDate(value || this.value));
    }
    mounted() {
        this.$nextTick(() => this.setDate(this.value));
    }
    onValueChange(newVal) {
        this.setDate(newVal);
    }
};
__decorate([
    Prop({ type: Object, default: () => ({ appendChange: () => ({}) }) }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "textFieldProps", void 0);
__decorate([
    Prop({ type: Object, default: () => ({ message: '', error: false }) }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "validate", void 0);
__decorate([
    Prop({ type: [String, Array], default: () => '' }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "value", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "name", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: () => false }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "disabled", void 0);
__decorate([
    Prop({ type: Array, default: () => [] }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "rules", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "isShortYear", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "min", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Object)
], CustomDatepicker.prototype, "max", void 0);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CustomDatepicker.prototype, "onValueChange", null);
CustomDatepicker = __decorate([
    Component
], CustomDatepicker);
export default CustomDatepicker;
