import EmptyLayout from '@/layouts/index.vue';
const companyRoutes = [
    {
        path: 'course-landing/:course_id',
        component: EmptyLayout,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "CourseLanding" */ '@/pages/CourseLanding/index.vue'),
                name: 'CourseLanding',
                meta: { title: 'Курс - лендинг' }
            }
        ]
    },
    {
        path: 'course-landing-new/:course_id',
        component: EmptyLayout,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "CourseLanding" */ '@/pages/CourseLandingNew/index.vue'),
                name: 'CourseLandingNew',
                meta: { title: 'Курс - лендинг' }
            }
        ]
    },
    {
        path: 'updated-course-landing/:course_id',
        component: EmptyLayout,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "UpdatedCourseLanding" */ '@/pages/UpdatedCourseLanding/index.vue'),
                name: 'UpdatedCourseLanding',
                meta: { title: 'Курс - лендинг' }
            }
        ]
    },
    {
        path: 'physics-course-landing/:course_id',
        component: EmptyLayout,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "PhysicsCourseLanding" */ '@/pages/PhysicsCourseLanding/index.vue'),
                name: 'PhysicsCourseLanding',
                meta: { title: 'Курс - лендинг' }
            }
        ]
    },
    {
        path: 'course-edit',
        component: EmptyLayout,
        children: [
            {
                path: '',
                name: 'course-edit',
                component: () => import(/* webpackChunkName: "CourseEdit" */ '@/pages/CourseEdit/index.vue'),
                meta: { title: 'Редактирование CRM курсов' }
            }
        ]
    },
    {
        path: 'compilation-landing/:compilation_id',
        component: EmptyLayout,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "CourseLanding" */ '@/pages/CompilationLanding/index.vue'),
                name: 'CompilationLanding',
                meta: { title: 'Лендинг подборки' }
            }
        ]
    },
    {
        path: 'course-search',
        component: EmptyLayout,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "CourseSearch" */ '@/pages/CourseSearch/index.vue'),
                name: 'course-search',
                meta: { title: 'Поиск курсов' }
            }
        ]
    },
    {
        path: 'instructions/:type',
        component: () => import(/* webpackChunkName: "Instructions" */ '@/layouts/Instructions/index.vue'),
        props: true,
        beforeEnter(to, from, next) {
            const { type } = to.params;
            to.params.type = type === 'admin' ? 'admin' : 'public';
            to.meta.title = `Инструкции ${type !== 'admin' ? 'учебного кабинета' : 'в административной панели'}`;
            if (!type) {
                next('instructions/public/');
            }
            else {
                next();
            }
        },
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "InstructionCategoryList" */ '@/pages/Instructions/index.vue'),
                name: 'InstructionCategoryList',
                props: true,
                meta: { title: 'Категории инструкций' }
            },
            {
                path: ':category',
                component: () => import(/* webpackChunkName: "InstructionCategory" */ '@/pages/Instructions/list.vue'),
                name: 'InstructionCategory',
                props: true,
                meta: { title: 'Категория инструкций' }
            },
            {
                path: ':category/:instruction',
                component: () => import(/* webpackChunkName: "InstructionDetail" */ '@/pages/Instructions/detail.vue'),
                name: 'InstructionDetail',
                props: true,
                meta: { title: 'Инструкция' }
            }
        ]
    },
    {
        path: 'courses/:course_id',
        component: () => import(/* webpackChunkName: "CourseAndLesson" */ '@/layouts/CourseAndLesson.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "Course" */ '@/pages/Course/index.vue'),
                name: 'Course'
            },
            {
                path: 'lessons/:lesson_id',
                component: () => import(/* webpackChunkName: "LessonPage" */ '@/pages/Lesson/index.vue'),
                name: 'Lesson'
            }
        ]
    },
    {
        path: 'tests/:test_id',
        component: () => import(/* webpackChunkName: "FreeTest" */ '@/pages/Test/index.vue'),
        name: 'FreeTest'
    },
    {
        path: 'courses/:course_id/lessons/:lesson_id/tests/:test_id',
        component: () => import(/* webpackChunkName: "AdminTest" */ '@/pages/Test/index.vue'),
        name: 'AdminTest'
    },
    {
        path: '',
        name: 'AdminLayout',
        component: () => import(/* webpackChunkName: "AdminLayout" */ '@/layouts/AdminLayout.vue'),
        children: [
            {
                path: 'promo-codes',
                component: () => import(/* webpackChunkName: "promoCode" */ '@/pages/PromoCodes/index.vue'),
                name: 'PromoCodes',
                meta: { title: 'Промокоды' }
            },
            {
                path: 'promo-codes/code',
                component: () => import(/* webpackChunkName: "promoCode" */ '@/pages/PromoCode/index.vue'),
                name: 'PromoCodeNew',
                meta: { title: 'Создать промокод', backLink: 'PromoCodes' },
                children: [
                    {
                        path: ':promo_id',
                        component: () => import(/* webpackChunkName: "promoCode" */ '@/pages/PromoCode/index.vue'),
                        name: 'PromoCodeEdit',
                        meta: { title: 'Редактировать промокод', backLink: 'PromoCodes' }
                    }
                ]
            },
            {
                path: 'users',
                component: () => import(/* webpackChunkName: "Users" */ '@/pages/Users/index.vue'),
                name: 'UsersList',
                meta: { title: 'Пользователи' }
            },
            {
                path: 'users/:user_id',
                component: () => import(/* webpackChunkName: "Users" */ '@/pages/User/index.vue'),
                name: 'User',
                meta: { title: 'Редактировать пользователя' }
            },
            {
                path: 'generate',
                component: () => import(/* webpackChunkName: "GenerateAccounts" */ '@/pages/GenerateAccounts/index.vue'),
                name: 'GenerateAccounts',
                meta: { title: 'Генерация аккаунтов' }
            },
            {
                path: 'news',
                component: () => import(/* webpackChunkName: "News" */ '@/pages/News/index.vue'),
                name: 'news',
                meta: { title: 'Новости' }
            },
            {
                path: 'updating-platform',
                component: () => import(/* webpackChunkName: "UpdatingPlatform" */ '@/pages/UpdatingPlatform/index.vue'),
                name: 'updating-platform',
                meta: { title: 'Обновление платформы' }
            },
            {
                path: 'updating-platform/releases/create',
                component: () => import(/* webpackChunkName: "UpdatingPlatformCreate" */ '@/pages/UpdatingPlatform/create.vue'),
                name: 'updating-platform-new',
                meta: { title: 'Обновление платформы -  Новый релиз' }
            },
            {
                path: 'updating-platform/releases/:releaseId/edit',
                component: () => import(/* webpackChunkName: "UpdatingPlatformEdit" */ '@/pages/UpdatingPlatform/edit.vue'),
                name: 'updating-platform-edit',
                meta: { title: 'Обновление платформы -  Обновить релиз' }
            },
            {
                path: 'updating-platform/ideas',
                component: () => import(/* webpackChunkName: "Ideas" */ '@/pages/Ideas/index.vue'),
                name: 'updating-platform-ideas',
                meta: { title: 'Список идей' }
            },
            {
                path: 'invites',
                name: 'invites',
                component: () => import(/* webpackChunkName: "Invites" */ '@/pages/Invites/index.vue'),
                meta: { title: 'Инвайт-коды' }
            },
            {
                path: 'invites/:inviteId',
                component: () => import(/* webpackChunkName: "Invites" */ '@/pages/Invites/index.vue'),
                name: 'invite',
                meta: {
                    title: 'Инвайт-коды',
                    backLink: 'invites'
                }
            },
            {
                path: 'welcomes',
                name: 'welcomes',
                component: () => import(/* webpackChunkName: "Invites" */ '@/pages/Invites/index.vue'),
                meta: { title: 'Приглашения пользователей' }
            },
            {
                path: 'welcomes/:inviteId',
                component: () => import(/* webpackChunkName: "Invites" */ '@/pages/Invites/index.vue'),
                name: 'welcome',
                meta: {
                    title: 'Приглашения пользователей',
                    backLink: 'welcomes'
                }
            },
            {
                path: 'forms',
                component: () => import(/* webpackChunkName: "FormsPage" */ '@/pages/Forms/index.vue'),
                name: 'FormsPage',
                meta: { title: 'Формы' }
            },
            {
                path: 'forms/:formId',
                component: () => import(/* webpackChunkName: "FormPage" */ '@/pages/Form/Form.vue'),
                name: 'form',
                meta: {
                    title: 'Редактировать форму',
                    backLink: 'FormsPage'
                }
            },
            {
                path: 'groups',
                component: () => import(/* webpackChunkName: "GroupsList" */ '@/pages/GroupsList/index.vue'),
                name: 'GroupsList',
                meta: { title: 'Группы' }
            },
            {
                path: 'events',
                component: () => import(/* webpackChunkName: "Events" */ '@/pages/Events/index.vue'),
                name: 'events',
                meta: { title: 'Мероприятия' }
            },
            {
                path: 'custom-fields',
                component: () => import(/* webpackChunkName: "CustomFields" */ '@/pages/CustomFields/index.vue'),
                name: 'custom-fields-list',
                meta: { title: 'Кастомные поля' }
            },
            {
                path: 'reviews',
                component: () => import(/* webpackChunkName: "Reviews" */ '@/pages/Reviews/index.vue'),
                name: 'reviewsList',
                meta: { title: 'Отзывы пользователей' }
            },
            {
                path: 'reviews/:review_id',
                component: () => import(/* webpackChunkName: "ReviewPage" */ '@/pages/Reviews/ReviewPage.vue'),
                name: 'reviewPage',
                meta: {
                    title: 'Отзывы',
                    backLink: 'reviewsList',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'courses-and-payment',
                component: () => import(/* webpackChunkName: "CustomFields" */ '@/pages/CoursesAndPayments/index.vue'),
                name: 'courses-and-payment',
                meta: { title: 'Курсы и оплата' }
            },
            {
                path: 'custom-fields/:customFieldId',
                component: () => import(/* webpackChunkName: "CustomFieldPage" */ '@/pages/CustomFieldDetails/index.vue'),
                name: 'custom-fields',
                beforeEnter(to, from, next) {
                    const { customFieldId } = to.params;
                    const isNewRole = isNaN(Number(customFieldId));
                    to.meta.title = !isNewRole ? 'Редактировать кастомное поле' : 'Создать кастомное поле';
                    next();
                },
                meta: {
                    title: 'Роль',
                    backLink: 'custom-fields-list'
                }
            },
            {
                path: 'library',
                component: () => import(/* webpackChunkName: "Library" */ '@/pages/Library/index.vue'),
                name: 'Library',
                meta: { title: 'Медиабиблиотека' }
            },
            {
                path: 'roles',
                component: () => import(/* webpackChunkName: "RolesList" */ '@/pages/RolesList/index.vue'),
                name: 'RolesList',
                meta: { title: 'Роли' }
            },
            {
                path: 'roles/:role_id',
                component: () => import(/* webpackChunkName: "Role" */ '@/pages/Role/Role.vue'),
                name: 'role',
                beforeEnter(to, from, next) {
                    const { role_id } = to.params;
                    const isNewRole = isNaN(Number(role_id));
                    to.meta.title = isNewRole ? 'Создать роль' : 'Редактировать роль';
                    next();
                },
                meta: {
                    title: 'Роль',
                    backLink: 'RolesList'
                }
            },
            {
                path: 'auto-actions',
                component: () => import(/* webpackChunkName: "AutoActionsList" */ '@/pages/AutoActions/index.vue'),
                name: 'AutoActions',
                meta: { title: 'Автодействия' }
            },
            {
                path: 'auto-actions/:id',
                component: () => import(/* webpackChunkName: "AutoActionDetails" */ '@/pages/AutoActions/AutoAction.vue'),
                name: 'AutoActionDetail',
                props: true,
                beforeEnter(to, from, next) {
                    const { id } = to.params;
                    const isNew = isNaN(Number(id));
                    to.meta.title = isNew ? 'Создать автодействие' : 'Редактировать автодействие';
                    next();
                },
                meta: {
                    title: 'Автодействие',
                    backLink: 'AutoActions'
                }
            },
            {
                path: 'auto-action-integrations',
                component: () => import(/* webpackChunkName: "AutoActionsList" */ '@/pages/AutoActions/index.vue'),
                name: 'AutoActionIntegrations',
                meta: { title: 'Интеграции автодействий' }
            },
            {
                path: 'auto-action-integrations/:id/:copy?',
                component: () => import(/* webpackChunkName: "AutoActionIntegrationDetail" */ '@/pages/AutoActions/AutoActionIntegration.vue'),
                name: 'AutoActionIntegrationDetail',
                props: true,
                beforeEnter(to, from, next) {
                    const { id, copy } = to.params;
                    const isNew = isNaN(Number(id));
                    to.meta.title = isNew || copy === 'copy' ? 'Создать интеграцию автодействия' : 'Редактировать интеграцию автодействия';
                    next();
                },
                meta: {
                    title: 'Интеграция автодействия',
                    backLink: 'AutoActionIntegrations'
                }
            },
            {
                path: 'auto-action-templates',
                component: () => import(/* webpackChunkName: "AutoActionsList" */ '@/pages/AutoActions/index.vue'),
                name: 'AutoActionTemplates',
                meta: { title: 'Шаблоны автодействий' }
            },
            {
                path: 'auto-action-templates/:id/:copy?',
                component: () => import(/* webpackChunkName: "AutoActionTemplateDetail" */ '@/pages/AutoActions/AutoActionTemplate.vue'),
                name: 'AutoActionTemplateDetail',
                props: true,
                beforeEnter(to, from, next) {
                    const { id, copy } = to.params;
                    const isNew = isNaN(Number(id));
                    to.meta.title = isNew || copy === 'copy' ? 'Создать шаблон автодействия' : 'Редактировать шаблон автодействия';
                    next();
                },
                meta: {
                    title: 'Шаблоны автодействия',
                    backLink: 'AutoActionTemplates'
                }
            },
            {
                path: 'courses',
                component: () => import(/* webpackChunkName: "CoursesList" */ '@/pages/CoursesList/index.vue'),
                name: 'CoursesList',
                meta: { title: 'Курсы' }
            },
            {
                path: 'course-types',
                component: () => import(/* webpackChunkName: "CourseTypes" */ '@/pages/CourseTypes/index.vue'),
                name: 'CourseTypes',
                meta: { title: 'Кастомные типы курсов' }
            },
            {
                path: 'tests',
                component: () => import(/* webpackChunkName: "TestsTable" */ '@/pages/Tests/Tests.vue'),
                name: 'tests',
                meta: { title: 'Тесты' }
            },
            {
                path: 'categories',
                component: () => import(/* webpackChunkName: "CategoriesPage" */ '@/pages/Categories/index.vue'),
                name: 'categories',
                meta: { title: 'Категории' }
            },
            {
                path: 'categories/:category_id',
                component: () => import(/* webpackChunkName: "Subcategories" */ '@/pages/Categories/subcategories.vue'),
                name: 'subcategories',
                meta: {
                    title: 'Подкатегории',
                    backLink: 'categories'
                }
            },
            {
                path: 'orders',
                component: () => import(/* webpackChunkName: "OrdersPage" */ '@/pages/Orders/index.vue'),
                name: 'orders',
                meta: { title: 'Заявки' }
            },
            {
                path: 'orders/:order_id',
                component: () => import(/* webpackChunkName: "CrmPage" */ '@/pages/Order/index.vue'),
                name: 'crm-page',
                meta: {
                    title: 'Заявки',
                    backLink: 'orders'
                }
            },
            {
                path: 'statuses',
                component: () => import(/* webpackChunkName: "StatusesPage" */ '@/pages/Statuses/index.vue'),
                name: 'statuses',
                meta: { title: 'Статусы' }
            },
            {
                path: 'settings',
                component: () => import(/* webpackChunkName: "SettingsPage" */ '@/pages/Profile/ProfilePage.vue'),
                name: 'settings',
                meta: { title: 'Настройки компании' }
            },
            {
                path: 'tests/:test_id/questions',
                component: () => import(/* webpackChunkName: "QuestionsPage" */ '@/pages/Questions/Questions.vue'),
                name: 'questions',
                meta: {
                    title: 'Вопросы к тесту',
                    backLink: 'tests'
                }
            },
            {
                path: 'tests/:test_id/results',
                component: () => import(/* webpackChunkName: "TestResults" */ '@/pages/TestResults/index.vue'),
                name: 'TestResults',
                meta: {
                    title: 'Результаты теста',
                    backLink: 'tests'
                }
            },
            {
                path: 'lesson-test-results/:test_id',
                component: () => import(/* webpackChunkName: "LessonTestResults" */ '@/pages/TestResults/index.vue'),
                name: 'LessonTestResults'
            },
            {
                path: 'public-menu',
                component: () => import(/* webpackChunkName: "PublicMenu" */ '@/pages/PublicMenu/index.vue'),
                name: 'PublicMenu',
                meta: { title: 'Редактировать меню' }
            },
            {
                path: 'catalog-settings',
                component: () => import(/* webpackChunkName: "CatalogSettings" */ '@/pages/CatalogSettings/index.vue'),
                name: 'CatalogSettings',
                meta: { title: 'Настроить каталог курсов' }
            },
            {
                path: 'course-compilations/:compilation_id',
                component: () => import(/* webpackChunkName: "CourseCompilation" */ '@/pages/CourseCompilation/index.vue'),
                name: 'CourseCompilation',
                meta: {
                    title: 'Подборки курсов',
                    backLink: 'CoursesList'
                },
                beforeEnter: async (to, from, next) => {
                    to.meta.title = to.params.compilation_id === 'create' ? 'Создать подборку' : 'Редактировать подборку';
                    next();
                }
            },
            /// tmp routes
            {
                path: 'faq',
                component: () => import(/* webpackChunkName: "FaqList" */ '@/pages/FaqList/index.vue'),
                name: 'faqList',
                meta: {
                    title: 'FAQ',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'faq/:faq_id',
                component: () => import(/* webpackChunkName: "FaqPage" */ '@/pages/FaqPage/index.vue'),
                name: 'faqPage',
                meta: {
                    title: 'FAQ',
                    backLink: 'faqList'
                }
            },
            {
                path: 'advantages',
                component: () => import(/* webpackChunkName: "FaqList" */ '@/pages/Advantages/index.vue'),
                name: 'advantagesList',
                meta: { title: 'Преимущества' }
            },
            {
                path: 'advantages/:advantage_id',
                component: () => import(/* webpackChunkName: "RatingPage" */ '@/pages/AdvantagePage/index.vue'),
                name: 'advantagePage',
                meta: {
                    title: 'Преимущества',
                    backLink: 'advantagesList',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'ratings',
                component: () => import(/* webpackChunkName: "RatingsList" */ '@/pages/Ratings/index.vue'),
                name: 'ratingsList',
                meta: { title: 'Рейтинги' }
            },
            {
                path: 'locations',
                component: () => import(/* webpackChunkName: "Locations" */ '@/pages/CourseLocation/index.vue'),
                name: 'locations',
                meta: { title: 'Локации' }
            },
            {
                path: 'achievements',
                component: () => import(/* webpackChunkName: "Achievements" */ '@/pages/CourseAchievements/index.vue'),
                name: 'achievements',
                meta: { title: 'Достижения' }
            },
            {
                path: 'ratings/:rating_id',
                component: () => import(/* webpackChunkName: "RatingPage" */ '@/pages/RaitingDetail/index.vue'),
                name: 'ratingPage',
                meta: {
                    title: 'Рейтинги',
                    backLink: 'ratingsList',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'course-news',
                component: () => import(/* webpackChunkName: "CourseNewsList" */ '@/pages/CourseNews/index.vue'),
                name: 'courseNews',
                meta: { title: 'Новости' }
            },
            {
                path: 'course-news/:news_id',
                component: () => import(/* webpackChunkName: "CourseNewsItem" */ '@/pages/CourseNewsDetail/index.vue'),
                name: 'courseNewsPage',
                meta: {
                    title: 'Новости',
                    backLink: 'courseNews',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'articles',
                component: () => import(/* webpackChunkName: "Articles" */ '@/pages/Articles/index.vue'),
                name: 'Articles',
                meta: { title: 'Статьи' }
            },
            {
                path: 'statistics',
                component: () => import(/* webpackChunkName: "Articles" */ '@/pages/GeneralStatistics/index.vue'),
                name: 'GeneralStatistics',
                meta: { title: 'Общие данные' }
            },
            {
                path: 'articles/:article_id',
                component: () => import(/* webpackChunkName: "ArticlePage" */ '@/pages/ArticlePage/index.vue'),
                name: 'ArticlePage',
                meta: { title: 'Редактировать статью', backLink: 'Articles' }
            },
            {
                path: 'course-groups',
                component: () => import(/* webpackChunkName: "CourseGroups" */ '@/pages/CourseGroups/index.vue'),
                name: 'CourseGroups',
                meta: { title: 'Группировки' }
            },
            {
                path: 'course-groups/:group_id',
                component: () => import(/* webpackChunkName: "CourseGroupsDetails" */ '@/pages/CourseGroupDetails/index.vue'),
                name: 'CourseGroupDetails',
                meta: { title: 'Редактировать группировку', backLink: 'CourseGroups' }
            },
            {
                path: 'file-storage',
                component: () => import(/* webpackChunkName: "FileManager" */ '@/pages/FileStorage/index.vue'),
                name: 'fileStorage',
                meta: { title: 'Хранилище файлов' }
            },
            {
                path: 'webinars',
                component: () => import(/* webpackChunkName: "Webinars" */ '@/pages/Webinars/index.vue'),
                name: 'webinars',
                meta: { title: 'Вебинары' }
            },
            {
                path: 'galleries',
                component: () => import(/* webpackChunkName: "Galleries" */ '@/pages/Galleries/index.vue'),
                name: 'galleries',
                meta: { title: 'Как проходят занятия' }
            },
            {
                path: 'moderate_reviews',
                component: () => import(/* webpackChunkName: "ModerateReviews" */ '@/pages/ModerateReviews/index.vue'),
                name: 'moderateReviews',
                meta: { title: 'Модерируемые отзывы' }
            },
            {
                path: 'moderate_reviews/:review_id',
                component: () => import(/* webpackChunkName: "ReviewPage" */ '@/pages/ModerateReviews/ReviewPage.vue'),
                name: 'moderateReviewPage',
                meta: {
                    title: 'Модерируемые отзывы',
                    backLink: 'moderateReviews',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'banners',
                component: () => import(/* webpackChunkName: "Banners" */ '@/pages/Banners/index.vue'),
                name: 'banners',
                meta: { title: 'Баннеры' }
            },
            {
                path: 'banners/page/:page_id',
                component: () => import(/* webpackChunkName: "BannersPage" */ '@/pages/Banners/ViewPage.vue'),
                name: 'viewPage',
                meta: {
                    title: 'Редактировать страницу',
                    backLink: 'banners',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'seo-pages',
                component: () => import(/* webpackChunkName: "SeoPages" */ '@/pages/SeoPages/index.vue'),
                name: 'seoPages',
                meta: { title: 'SEO страниц' }
            },
            {
                path: 'seo-pages/page/:page_id',
                component: () => import(/* webpackChunkName: "SeoPage" */ '@/pages/SeoPages/PageSettings.vue'),
                name: 'seoPage',
                meta: {
                    title: 'Редактировать SEO страницы',
                    backLink: 'seoPages',
                    headerCssClass: 'little-z-index'
                }
            },
            {
                path: 'united-tables',
                component: () => import(/* webpackChunkName: "UnitedTables" */ '@/pages/UnitedTables/index.vue'),
                name: 'united-tables',
                meta: { title: 'Сводная таблица' }
            },
            {
                path: 'registration-list/:course_id',
                component: () => import(/* webpackChunkName: "RegistrationList" */ '@/pages/RegistrationList/index.vue'),
                name: 'registration-list',
                meta: { title: 'Регистрационный лист' }
            },
            {
                path: 'plans-sales',
                component: () => import(/* webpackChunkName: "ManagerDashboard" */ '@/pages/ManagerDashboard/index.vue'),
                name: 'plans-sales',
                meta: { title: 'Планы и продажи' }
            },
            {
                path: 'leads-filter',
                component: () => import(/* webpackChunkName: "LeadsFilter" */ '@/pages/LeadsFilter/index.vue'),
                name: 'leads-filter',
                meta: { title: 'Фильтрация лидов' }
            },
            {
                path: 'olympic-tests',
                component: () => import(/* webpackChunkName: "OlympicTests" */ '@/pages/OlympicTests/index.vue'),
                name: 'olympic-tests',
                meta: { title: 'Олимпиадные тесты' }
            },
            {
                path: 'subjects',
                component: () => import(/* webpackChunkName: "OlympicTests" */ '@/pages/Subjects/index.vue'),
                name: 'Subjects',
                meta: { title: 'Предметы' }
            },
            {
                path: 'olympic-tests/folder/:folder_id/test/:test_id',
                component: () => import(/* webpackChunkName: "OlympicTestsDetail" */ '@/pages/OlympicTests/TestsDetail/index.vue'),
                name: 'olympic-tests-detail',
                meta: { title: 'Олимпиадные тесты', backLink: 'olympic-tests' }
            },
            {
                path: 'olympic-tests/folder/:folder_id/user/:user_id',
                component: () => import(/* webpackChunkName: "OlympicUserDetail" */ '@/pages/OlympicTests/UserDetail/index.vue'),
                name: 'olympic-user-detail',
                meta: { title: 'Олимпиадные тесты', backLink: 'olympic-tests' }
            },
            {
                path: 'stress-test',
                component: () => import(/* webpackChunkName: "StressTest" */ '@/pages/StressTest/index.vue'),
                name: 'StressTest',
                meta: { title: 'Нагрузочное тестирование' }
            },
            {
                path: 'universities',
                component: () => import(/* webpackChunkName: "Universities" */ '@/pages/Universities/index.vue'),
                name: 'Universities',
                meta: { title: 'ВУЗЫ' }
            },
            {
                path: 'universities/university/:university_id',
                component: () => import(/* webpackChunkName: "UniversityItem" */ '@/pages/UniversityItem/index.vue'),
                name: 'UniversityItem',
                meta: { title: 'ВУЗЫ', backLink: 'Universities' }
            },
            {
                path: 'sales-generator',
                component: () => import(/* webpackChunkName: "SalesGenerator" */ '@/pages/SalesGenerator/List/index.vue'),
                name: 'sales-generator',
                meta: { title: 'Генератор продаж' }
            },
            {
                path: 'sales-generator/generator/:generator_id',
                component: () => import(/* webpackChunkName: "SaleGenerator" */ '@/pages/SalesGenerator/Generator/index.vue'),
                name: 'sale-generator',
                meta: {
                    title: 'Редактировать Генератор',
                    backLink: 'sales-generator'
                }
            },
            {
                path: 'sales-generator/generator',
                component: () => import(/* webpackChunkName: "SaleGenerator" */ '@/pages/SalesGenerator/Generator/index.vue'),
                name: 'create-sale-generator',
                meta: {
                    title: 'Создать Генератор',
                    backLink: 'sales-generator'
                }
            },
            {
                path: 'info-blocks',
                component: () => import(/* webpackChunkName: "InfoBlocks" */ '@/pages/InfoBlocks/index.vue'),
                name: 'info-blocks',
                meta: {
                    title: 'Инфоблоки',
                }
            },
            {
                path: 'info-blocks/:block_id',
                component: () => import(/* webpackChunkName: "InfoBlock" */ '@/pages/InfoBlockItem/index.vue'),
                name: 'info-block',
                meta: {
                    title: 'Инфоблоки',
                    backLink: 'info-blocks',
                }
            },
            {
                path: 'info-blocks/:block_id/elements',
                component: () => import(/* webpackChunkName: "InfoBlockElements" */ '@/pages/InfoBlockElements/index.vue'),
                name: 'info-block-elements',
                meta: {
                    title: 'Инфоблоки',
                    backLink: 'info-blocks',
                }
            },
            {
                path: 'info-blocks/:block_id/elements/:element_id',
                component: () => import(/* webpackChunkName: "InfoBlockElementItem" */ '@/pages/InfoBlockElementItem/index.vue'),
                name: 'info-block-element-item',
                meta: {
                    title: 'Инфоблоки',
                    backLink: 'info-blocks',
                }
            },
            {
                path: 'templates',
                component: () => import(/* webpackChunkName: "TemplatesPage" */ '@/pages/Templates/index.vue'),
                name: 'templates',
                meta: {
                    title: 'Шаблоны',
                }
            },
            {
                path: '404',
                component: () => import(/* webpackChunkName: "NotFound" */ '@/pages/NotFound.vue'),
                name: 'NotFound',
                meta: { title: 'Такой страницы нет' }
            },
            {
                path: '*',
                redirect: '404'
            }
        ]
    }
];
export default companyRoutes;
