import buildDate from '@/helpers/BuildDate';
const actions = {
    async list({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/invites${query.inviteId ? `/${query.inviteId}/user_invites` : ''}`, params);
    },
    async getInviteInfo({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/invites/${id}`);
    },
    async activateInvite({ rootGetters }, payload) {
        const { id, active } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}`, { active }, 'PUT');
    },
    async activateInviteGroup({ rootGetters }, payload) {
        const { data, type } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${type}`, data);
    },
    async singleWelcomeActions({ rootGetters }, payload) {
        const { id, data, type } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}/user_invites/${type}`, data);
    },
    async createInvite({ rootGetters }, payload) {
        const { type, id, form: { name, description, course_compilations, user_limit, date_start, date_end, course_packages, roles, groups, tests, send_type, send_to, customFields, customFieldsRequired }, isUpdate } = payload;
        const invite = {
            name,
            description,
            active: true,
            send: true,
            type: type.slice(0, -1),
            date_start: buildDate(date_start, { monthType: 'dashedNumbers' }).rusDate.split(' ').reverse().join(''),
            date_end: date_end ? buildDate(date_end, { monthType: 'dashedNumbers' }).rusDate.split(' ').reverse().join('') : '',
            course_packages_id: course_packages.map((item) => (item.value ? item.value : item)),
            roles_id: roles.map((item) => (item.value ? item.value : item)),
            groups_id: groups.map((item) => (item.value ? item.value : item)),
            course_compilations_id: course_compilations.map((item) => (item.value ? item.value : item)),
            tests_id: tests.map((item) => (item.value ? item.value : item)),
            custom: customFields.map(({ value }) => ({ id: value, required: !!customFieldsRequired?.find((item) => item.value === value) ?? false }))
        };
        if (type === 'welcomes' && !isUpdate)
            send_type === 'phone' ? invite.phones = send_to : invite.emails = send_to;
        const method = isUpdate ? 'PUT' : 'POST';
        const url = `${rootGetters.API_URL}/invites${id ? `/${id}` : ''}`;
        const actions = {
            default: () => this.postDataStore(url, { ...invite, user_limit: Number(user_limit) }, method),
            welcomes: () => this.postDataStore(url, { ...invite, send_type }, method)
        };
        const action = actions[type] || actions.default;
        return await action();
    },
    async addUsersToWelcome({ rootGetters }, payload) {
        const { data, id } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}/user_invites/multi_store`, {
            send: 1,
            ...data
        });
    },
    async getInvite({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/invites/${id}`, {
            with: 'roles:name;groups:name;courses:name;tests:title;courseCompilations;connectedUser:name;coursePackages:name;coursePackages.course:name;coursePackages.courseBranch:id,name',
            withCount: 'activatedUserInvites'
        });
    },
    async deleteSingleInvite({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    actions
};
