import { render, staticRenderFns } from "./AdminHeader.vue?vue&type=template&id=3c856ec5&scoped=true&"
import script from "./AdminHeader.vue?vue&type=script&lang=ts&"
export * from "./AdminHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AdminHeader.vue?vue&type=style&index=0&id=3c856ec5&lang=scss&scoped=true&"
import style1 from "./AdminHeader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c856ec5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VList,VListItem,VMenu})
