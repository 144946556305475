import BuildDate from '@/helpers/BuildDate';
import BuildTabs from './helpers/BuildTabs';
import defaultState from './entities/DefaultState';
import { mixinActions, mixinMutations } from '@/store/mixins';
import router from '@/router';
import { QuestionNames } from '@/helpers/enteties/QuestionTypes';
const freezeState = { ...defaultState };
const state = defaultState;
const mutations = {
    ...mixinMutations(),
    clearState() {
        Object.entries(freezeState).forEach(([key, value]) => {
            state[key] = value;
        });
        state.currentTab = {
            text: 'Содержимое урока',
            value: 'settings'
        };
        state.test = null;
        state.test_id = null;
        state.test_questions = [];
    },
    setLessonData(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            if (state.hasOwnProperty(key)) {
                state[key] = value;
            }
        });
        const { video, webinar_video_recording } = payload;
        if (video) {
            const parsedVideo = JSON.parse(video);
            state.video = Array.isArray(parsedVideo) ? parsedVideo : [parsedVideo];
        }
        if (webinar_video_recording) {
            const parsedWebinarRecord = JSON.parse(webinar_video_recording);
            state.webinar_video_recording = Array.isArray(parsedWebinarRecord) ? parsedWebinarRecord : [parsedWebinarRecord];
        }
    },
    setCurrentStatus(state, payload) {
        state.currentStatus = payload;
    },
    setTest(state, payload) {
        const { questions, test } = payload;
        const typesVocabulary = QuestionNames;
        const updatedQuestions = questions.map((item) => {
            const { id, question, type, active, created_at, updated_at } = item;
            return {
                id,
                question,
                type: typesVocabulary[type] ? typesVocabulary[type] : typesVocabulary.default,
                active: active ? 'Да' : 'Нет',
                created_at: Number(BuildDate(created_at).jsDate),
                updated_at: Number(BuildDate(updated_at).jsDate),
                created_rus: BuildDate(created_at).rusDate,
                updated_rus: BuildDate(updated_at).rusDate
            };
        });
        state.test = test;
        state.test_questions = updatedQuestions;
    },
    cleanTestData(state) {
        state.test = null;
        state.test_questions = [];
    },
    removeQuestions(state, payload) {
        state.test_questions = state.test_questions.filter((item) => !payload.includes(item.id));
    },
    updateQuestionsActivity(state, { value, selected_id }) {
        state.test_questions = state.test_questions.map((item) => {
            if (selected_id.includes(item.id)) {
                item.active = value ? 'Да' : 'Нет';
            }
            return item;
        });
    },
    setTabs(state, type) {
        state.tabs = BuildTabs(type);
    },
    changeUserAccess(state, { user, action }) {
        if (action === 'attach')
            state.admin_access_users = [...state.admin_access_users.filter(({ id }) => id !== user.id), user];
    },
    updateBlock(state, { block_id, params }) {
        const blockIndex = state.blocks.findIndex((block) => block.id === block_id);
        state.blocks = [
            ...state.blocks.slice(0, blockIndex),
            { ...state.blocks[blockIndex], ...params },
            ...state.blocks.slice(blockIndex + 1)
        ];
    }
};
// eslint-disable-next-line
const actions = {
    ...mixinActions(),
    async getLesson({ commit, rootGetters, state }, { course_id, lesson_id }) {
        commit('setProperty', {
            name: 'lessonUrl',
            value: `${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}`
        });
        commit('setProperty', { name: 'courseUrl', value: `${rootGetters.API_URL}/courses/${course_id}` });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}`, {
            with: 'course:name,is_constructor;master:name,surname;adminAccessUsers;test;mediaCopy;otherMedia',
            withCount: 'likes,dislikes,pleasedReactions,indifferentReactions,displeasedReactions',
            withExists: 'webinarData'
        });
        commit('setLessonData', data);
        const { test } = data;
        const { status } = data;
        state.test_id = test ? test.id : null;
        const resultStatus = status || 'visible';
        const currentStatus = state.statuses.find((item) => item.value === resultStatus);
        const { type } = data;
        commit('setCurrentStatus', currentStatus);
        commit('setTabs', type);
    },
    async deleteLesson({ dispatch, state, rootState }, { course_id }) {
        const url = state.lessonUrl;
        const response = await this.postDataStore(url, {}, 'DELETE');
        await dispatch('showingSuccess', response);
        setTimeout(() => {
            router.push(`/${rootState.company.company_name}/courses/${course_id}`);
        }, 1000);
    },
    async sendPropertyAjax({ commit, state, dispatch }, { name, value, updateState = true }) {
        const response = await this.postDataStore(state.lessonUrl, { [name]: value, withExists: 'webinarData' }, 'PUT');
        if (updateState)
            commit('setProperty', { name, value });
        await dispatch('showingSuccess', response);
        return response;
    },
    async removeFileAjax({ dispatch }, id) {
        const url = `${state.lessonUrl}/delete_media/${id}`;
        const response = await this.postDataStore(url, {}, 'delete');
        await dispatch('showingSuccess', response);
    },
    async createTest({ state, commit, dispatch, rootGetters }, payload = {}) {
        const { id, course_id } = state;
        const response = await this.postDataStore(`${rootGetters.API_URL}/tests`, { lesson_id: id, ...payload }, 'POST');
        if (payload && payload.block_id)
            return response.data;
        const test_id = response.data.id;
        const params = { test_id, course_id, lesson_id: id };
        await dispatch('getTest', params);
        state.test_id = test_id;
        commit('setProperty', { name: 'test_id', value: test_id });
        await dispatch('showingSuccess', response);
    },
    async getTest({ commit, rootGetters }, { test_id }) {
        commit('setProperty', {
            name: 'testUrl',
            value: `${rootGetters.API_URL}/tests/${test_id}`
        });
        if (!test_id)
            return;
        const response = await this.getDataStore(state.testUrl, { with: 'questions' });
        const { questions, ...rest } = response.data;
        commit('setTest', { test: rest, questions });
    },
    sendTestAjax({ commit, rootGetters }, { action, selected, test_id }) {
        const question_id = selected.map((item) => item.id);
        const sendHelper = async (url) => {
            const { errors, wrong_ids } = await this.postDataStore(`${rootGetters.API_URL}/tests/${test_id}/questions/${url}`, { question_id });
            let result_ids;
            if (!errors) {
                result_ids = question_id.slice();
            }
            else {
                result_ids = question_id.filter((id) => !wrong_ids.includes(id));
            }
            return { result_ids, errors };
        };
        const actions = {
            unactive: async () => {
                const { result_ids, errors } = await sendHelper('deactivate');
                return { action: 'deactivate', result_ids, errors };
            },
            active: async () => {
                const { result_ids, errors } = await sendHelper('activate');
                return { action: 'active', result_ids, errors };
            },
            remove: async () => {
                const { result_ids, errors } = await sendHelper('delete');
                commit('removeQuestions', result_ids);
                return { action: 'remove', result_ids, errors };
            },
            default: () => ({})
        };
        return actions[action] ? actions[action]() : actions.default();
    },
    moveChange({ commit, dispatch, rootGetters }, payload) {
        const parsedPayload = typeof payload === 'string' ? payload : payload.type;
        if (!parsedPayload)
            return;
        const search = typeof payload === 'string' ? '' : payload && payload.search ? payload.search : '';
        commit('setProperty', [{ name: 'lesson_move', value: [] }, { name: 'courses_move', value: [] }]);
        const actions = {
            current: async () => {
                dispatch('changeCourse', state.course_id);
            },
            another: async () => {
                const response = await this.getDataStore(`${rootGetters.API_URL}/courses`, { only: 'name', search });
                const result = response.data.map((item) => {
                    const { id, name } = item;
                    return { text: name, value: id };
                });
                commit('setProperty', { name: 'courses_move', value: result });
            }
        };
        return actions[parsedPayload]();
    },
    async updateCourses({ commit, rootGetters }, payload) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/courses`, { only: 'name', search: payload });
        const result = response.data.map((item) => {
            const { id, name } = item;
            return { text: name, value: id };
        });
        commit('setProperty', { name: 'courses_move', value: result });
    },
    async changeCourse({ commit, rootGetters, state }, payload) {
        const parsedPayloadId = typeof payload === 'number' ? payload : payload.course_id;
        const noActionEndNoFilter = typeof payload !== 'object' ? false : Boolean(payload.noActionEndNoFilter);
        commit('setProperty', { name: 'lesson_move', value: [] });
        const params = {
            only: 'name,type',
            with: 'test:id,lesson_id',
            all: 1
        };
        if (noActionEndNoFilter)
            params.activeTests = true;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${parsedPayloadId}/lessons`, params);
        const acceptedLessons = ['test', 'webinar', 'full_video', 'constructor'];
        if (!data || !Array.isArray(data))
            return;
        const result = data
            .filter(({ type }) => acceptedLessons.includes(type))
            .map(({ id, name, test }) => ({
            text: name,
            value: id,
            disabled: (test || state.id === id) && !noActionEndNoFilter
        }));
        commit('setProperty', { name: 'lesson_move', value: result });
    },
    async moveTest({ rootGetters }, { lesson_id }) {
        const testId = state.test.id;
        const url = `${rootGetters.API_URL}/tests/${testId}/replicate`;
        return await this.postDataStore(url, { lesson_id }, 'POST');
    },
    async removeTest({ commit, rootGetters }, test_id) {
        const url = test_id ? `${rootGetters.API_URL}/tests/${test_id}` : state.testUrl;
        const response = await this.postDataStore(url, {}, 'DELETE');
        if (!response.errors)
            commit('cleanTestData');
        return response;
    },
    async lessonUsers({ rootGetters, state }, payload) {
        const { course_id, id } = state;
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${id}/students`, params);
    },
    async lessonLikes({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/likes`, payload.params);
    },
    async getWebinarLink({ rootGetters, state }) {
        const { course_id, id } = state;
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${id}/admin_webinar_link`);
    },
    async deleteMediaRemote(_, { url, id }) {
        return await this.postDataStore(`${url}/delete_media/${id}`, {}, 'DELETE');
    },
    async getWebinarUsers({ rootGetters, state, commit }, search) {
        const { course_id, id } = state;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${id}/admins_and_teachers`, {
            only: 'id,name,surname',
            search
        });
        commit('setProperty', { name: 'admins_and_teachers', value: data });
    },
    async copyLesson({ rootGetters }, { lesson_id, course_id, courseIds }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/replicate_to_courses`, { courseIds });
    },
    async getLessonHistory({ rootGetters }, { course_id, lesson_id, filters, page = 1 }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/audits`, {
            ...filters,
            page
        });
    },
    async getHwExceptions({ rootGetters }, { course_id, lesson_id, payload = {} }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/allow_homework_users`, { ...payload, with: 'user' });
    },
    async addHwException({ rootGetters }, { course_id, lesson_id, payload = {} }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/allow_homework_users`, payload, 'POST');
    },
    async deleteHwException({ rootGetters }, { course_id, lesson_id, user_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/allow_homework_users/${user_id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
