import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/adminProfile/defaultState';
const state = defaultState();
const getters = {
    initials(state) {
        return !state.name || !state.surname ? '' : `${state.name[0]}${state.surname[0]}`;
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    async getProfile({ commit, state }) {
        if (state.id)
            return { data: state };
        const response = await this.getDataStore('/api/auth_user');
        commit('setState', response.data);
        return response;
    },
    async getSuperAdmin({ commit, rootGetters }) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/super-admin`);
        commit('setProperty', { name: 'superAdmin', value: response.data });
        return response;
    },
    async getNewReleases({ commit, rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/updating-platform/show-releases-unviewed`);
        const { unviewed_releases_count } = data;
        commit('setProperty', { name: 'unviewed_releases_count', value: unviewed_releases_count });
        return data;
    },
    async updateProfile({ commit }, payload) {
        const response = await this.postDataStore('/api/auth_user/update', payload, 'PUT');
        commit('setState', response.data);
        return response;
    },
    async logOutUser({ rootGetters }) {
        return await this.postDataStore(`${rootGetters.API_URL}/logout`, {});
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
